<template>
  <Modal
    :header="edit ? 'Edit User' : 'Create User'"
    :okLabel="edit ? 'Edit User' : 'Create User'"
    :show="show"
    :loadingSubmit="loading"
    width="700px"
    @close="close"
    @submit="submit"
  >
    <q-form ref="userForm">
      <q-card flat>
        <q-card-section>
          <h6 class="bg-white relative z-10 w-32 ml-4 px-2">User Details</h6>
          <div
            class="ml-1 p-4 pr-8 pb-8 column q-gutter-md border rounded border-gray"
          >
            <div>
              <FormInput
                label="First Name"
                icon="person"
                tabindex="1"
                v-model="firstName"
                :rules="[VALIDATION.required]"
              />
            </div>

            <div>
              <FormInput
                label="Last Name"
                icon="person"
                tabindex="2"
                v-model="lastName"
                :rules="[VALIDATION.required]"
              />
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <h6 class="bg-white relative z-10 w-40 ml-4 px-2">Account Details</h6>
          <div
            class="ml-1 p-4 pr-8 pb-8 column q-gutter-md border rounded border-gray"
          >
            <div v-if="!edit">
              <FormInput
                label="Email"
                icon="email"
                tabindex="3"
                v-model="email"
                :rules="[VALIDATION.required]"
              />
            </div>

            <div v-if="!edit">
              <div class="row items-start q-col-gutter-sm">
                <div class="col-sm-11">
                  <FormInput
                    label="Password"
                    icon="lock"
                    tabindex="4"
                    minlength="6"
                    maxlength="12"
                    hint="Password length should be between 6 and 12 characters"
                    v-model="password"
                    :rules="[
                      VALIDATION.required,
                      val => VALIDATION.requiredLength(val, 6, 12)
                    ]"
                    password
                  />
                </div>

                <div class="col-sm-1">
                  <Button
                    variant="primary"
                    icon="key"
                    flat
                    round
                    @click="generatePassword"
                  />
                  <Tooltip text=" Generate Password" />
                </div>
              </div>
            </div>

            <div>
              <SelectRoles
                icon="switch_account"
                v-model="role"
                :values="role"
                :outlined="false"
                @updateStore="$event => (role = $event)"
                tabindex="5"
              />
            </div>

            <div v-if="isPrincipal">
              <SelectRetailers
                icon="home"
                v-model="retailer"
                :values="retailer"
                :outlined="false"
                @updateStore="$event => (retailer = $event)"
                tabindex="6"
              />
            </div>

            <div v-if="edit">
              <FormSelect
                label="Status"
                icon="key"
                v-model="status"
                :values="status"
                :options="statusOptions"
                :rules="[VALIDATION.requiredArray]"
                :loading="false"
                :disabled="false"
                :outlined="false"
                tabindex="7"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-form>
  </Modal>
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import PasswordGenerator from 'generate-password'

import { FormInput, FormSelect } from '@/components/inputs'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import Tooltip from '@/components/Tooltip'
import { SelectRoles, SelectRetailers } from '@/components/customs'

import store from '@/store'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FormInput,
    Button,
    FormSelect,
    Modal,
    Tooltip,
    SelectRoles,
    SelectRetailers
  },
  setup(props, { emit }) {
    const {
      data: dataUser,
      error: errorUser,
      loading: loadingUser,
      get: getUser
    } = fetchData()
    const { showToast } = Toast()

    const isPrincipal = ref(store?.state?.accountType?.principal)
    const principalID = ref(store?.state?.profile?.principal_id)
    const userForm = ref(null)
    const userID = ref(null)
    const firstName = ref(null)
    const lastName = ref(null)
    const email = ref(null)
    const password = ref(null)
    const role = ref(null)
    const retailer = ref(null)
    const retailerID = ref(null)
    const statusOptions = [
      {
        label: 'Active',
        value: 'A'
      },
      {
        label: 'Disabled',
        value: 'D'
      }
    ]
    const status = ref(statusOptions[0].value)

    const close = () => {
      clearForm()
      emit('close')
    }

    const submit = () => {
      userForm.value.validate().then(success => {
        if (success) {
          let data = {
            id: props?.data?.id,
            firstName: firstName?.value,
            lastName: lastName?.value,
            email: email?.value,
            password: password?.value,
            role: role.value,
            principalID: principalID.value,
            retailer: retailer.value,
            retailerID: retailerID.value,
            status: status.value
          }

          emit('submit', data)
        }
      })
    }

    const clearForm = () => {
      firstName.value = null
      lastName.value = null
      email.value = null
      password.value = null
      role.value = null
      retailer.value = null
      retailerID.value = null
      status.value = null
    }

    const getUserDetails = () => {
      getUser(`v1/user/user-data/${props?.data?.id}`)
    }

    const generatePassword = () => {
      const generatedPassword = PasswordGenerator.generate({
        length: 12,
        numbers: true,
        symbols: true
      })

      password.value = generatedPassword
    }

    watch(
      () => props.success,
      () => {
        if (props.success) {
          close()
        }
      }
    )

    watch(
      () => props.edit,
      () => {
        if (props.edit) {
          getUserDetails()
        }
      }
    )

    onBeforeMount(() => {
      watch([dataUser, errorUser, loadingUser], () => {
        if (!loadingUser.value) {
          if (errorUser?.value) {
            showToast('There was a problem fetching stores.', 'danger')
          } else if (dataUser?.value) {
            const item = dataUser?.value

            userID.value = item?.id
            email.value = item?.email
            password.value = item?.password
            firstName.value = item?.first_name
            lastName.value = item?.last_name
            role.value = parseInt(item?.role_id)
            principalID.value = item?.principal_id
            retailer.value = item?.retailer_access?.map(item => item.id)
            retailerID.value = item?.retailer_id
            status.value = item?.status
          }
        }
      })
    })

    return {
      isPrincipal,
      userForm,
      firstName,
      lastName,
      email,
      password,
      role,
      retailer,
      status,
      statusOptions,
      VALIDATION,
      close,
      submit,
      clearForm,
      generatePassword,
      loadingUser
    }
  }
}
</script>
